import React, { useEffect } from "react";

import { Button, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import MainLayout from "../../../component/MainLayout";
import {
  useDelStaticMutation,
  useGetStaticQuery,
} from "../../../redux/Api/adminApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showEditModalOpen } from "../../../redux/slice/healthSlice";

const Term = () => {
  const { data, isLoading } = useGetStaticQuery();
  const [delStatic, response] = useDelStaticMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Term & Conditions",
      render: (record) =>
        record &&
        record?.term_conditions?.replace(/(<([^>]+)>)/gi, "")?.replace(/&nbsp;/g, " "),
    },
    {
      key: "3",
      title: "Action",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => edittnc(record)}
              className="editIcon"
            />
            <DeleteOutlined
              onClick={() => deltnc(record)}
              className="delIcon"
            />
          </>
        );
      },
    },
  ];

  const edittnc = (record) => {
    dispatch(showEditModalOpen(record));
    navigate("/static/update-term-and-condition");
  };

  const deltnc = (record) => {
    let del = { condition: 0 };
    const data = { id: record._id, type: del };
    delStatic(data);
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <MainLayout>
      <div className="table">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button className="gred-button">
            <a href={`/view-term-condation`} target="_blank" rel="noreferrer">
              View
            </a>
          </Button>
        </div>
        <Table
          dataSource={[data && data.data]}
          columns={columns}
          scroll={{ y: 400, x: 1000 }}
          bordered
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

export default Term;
