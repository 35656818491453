
import React from "react";
import { useGetStaticQuery } from "../../../redux/Api/adminApi";

const ViewTc = () => {
  const { data } = useGetStaticQuery();
  const viewData =
    data &&
    data.data.term_conditions
      .replace(/(<([^>]+)>)/gi, "")
      .replace(/&nbsp;/g, " ");
  return (
   <div className="view">
        <div>
          <h1 className="view_header">Term and Condition</h1>
        </div>
        <hr className="view_hr" />
        <p className="view_para">{viewData}</p>
      </div>
  );
};

export default ViewTc;
