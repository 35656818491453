import { Button, Form, Input ,Alert} from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../component/MainLayout";
import {
  usePostStaticMutation,
  useGetStaticQuery,
} from "../../redux/Api/adminApi";

const PostCondation = () => {
  const [postStatic, response] = usePostStaticMutation();
  const { data } = useGetStaticQuery();
  //    console.log(data)

  const onFinish = (value) => {
    postStatic(value);
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    } else if (response && response.error) {
      toast.error(response.error.data.message);
    }
  }, [response]);

  return (
    <>
      <MainLayout>
        <div className="form-user">
          <h1 className="form-head"> Add Condition</h1>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="Term & Conditions" name="term_conditions">
              <Input />
            </Form.Item>

            <Form.Item label="Privacy Policy" name="privacy_policy">
              <Input />
            </Form.Item>

            {data && data.data._id ? (
                <>
              <Button disabled className="gred-button">
                Add Condition
              </Button>
              <Alert message="Alrady Condation Add You Can Update Condation" type="error" />
              </>
            ) : (
              <Button type="primary" htmlType="submit" className="gred-button">
                Add Condition
              </Button>
            )}
          </Form>
        </div>
      </MainLayout>
    </>
  );
};

export default PostCondation;
