import { Table, Input } from "antd";
import MainLayout from "../../component/MainLayout";
import { useGetBookingQuery } from "../../redux/Api/adminApi";
import dayjs from "dayjs";
import { useSearch } from "../../hooks/useSearch";
import SearchBar from "../../component/SearchBar";

const Complete = () => {
  const { data, isLoading } = useGetBookingQuery(5);

  const [completedBookings, setSearch] = useSearch(data);

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Doctor Name",
      render: (record) => record.doctorId && record.doctorId.name,
    },
    {
      key: "3",
      title: "User Name",
      render: (record) => record.userId.name,
    },
    {
      key: "4",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "5",
      title: "Date",
      render: (record) =>
        new Date(record.selectedDateTime).toLocaleDateString(),
    },
    {
      key: "6",
      title: " Start Time",
      render: (record) => dayjs(record?.startTime).format("hh:mm A"),
    },
    {
      key: "7",
      title: " End Time",
      render: (record) => dayjs(record?.endTime).format("hh:mm A"),
    },
    {
      key: "8",
      title: "Payment Breakdown",
      render: (record) => {
        const { levelTwoProviderShare, adminShare, levelOneProviderShare } = record.paymentBreakdown || {};
        return (
          <>
            
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
              <div style={{ color: '#4CAF50', fontSize: '16px' }}>
                <strong>Level 1: </strong>
                <span>${levelOneProviderShare.toFixed(2)}</span>
              </div>
              <div style={{ color: '#2196F3', fontSize: '16px' }}>
                <strong>Level 2: </strong>
                <span>${levelTwoProviderShare.toFixed(2)}</span>
              </div>
              <div style={{ color: '#FF5722', fontSize: '16px' }}>
                <strong>Admin: </strong>
                <span>${adminShare.toFixed(2)}</span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <MainLayout>
        <div className="search-bar">
          <SearchBar setSearch={setSearch} />
        </div>
        <div className="table">
          <Table
            dataSource={completedBookings}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default Complete;
